/*
  If the number of TAB files has changed, the following variable is required.
  And it must be defined before `@import`.
*/
$tab-count: 5; // plus 1 for home tab

@import "jekyll-theme-chirpy";

/* append your custom style below */

h1 {
  font-size: 2.1rem;
}

h2 {
  font-size: 1.9rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 1rem;
}

#core-wrapper h2 {
  margin-top: 3.5rem;
}

#sidebar #avatar > a {
  border: none;
  border-radius: initial;
}

#sidebar #avatar img:hover {
  -ms-transform: initial;
  -moz-transform: initial;
  -webkit-transform: initial;
  transform: initial;
}

.table-wrapper>table tbody tr td {
  white-space: initial;
}

// Disable image loading animation
img[data-src][data-loaded=true] {
  animation: initial;
}
